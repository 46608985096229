import { WorkerTabs } from '@/util/axios/types';

export enum GAEvent {
  Signup = 'signup',
  FirstLogin = 'first_login',
  BillingSetup = 'billing_setup',
  SetPaymentMethod = 'set_payment_method',
  Login = 'login',
  TimesheetSave = 'timesheet_save',
  TimesheetApprove = 'timesheet_approve',
  TimesheetReject = 'timesheet_reject',
  TimesheetUnReject = 'timesheet_unreject',
  TimesheetUnapprove = 'timesheet_unapprove',
  StepOneGig = 'step_one_gig',
  StepTwoGig = 'step_two_gig',
  StepThreeGig = 'step_three_gig',
  StepFourMarkupGig = 'step_four_markup_gig',
  StepFourStaticGig = 'step_four_static_gig',
  StepFiveMarkupGig = 'step_five_markup_gig',
  StepFiveStaticGig = 'step_five_static_gig',
  StepSixMarkupGig = 'step_six_markup_gig',
  StepOneLta = 'step_one_lta',
  StepTwoLta = 'step_two_lta',
  StepThreeLta = 'step_three_lta',
  StepFourMarkupLta = 'step_four_markup_lta',
  StepFourStaticLta = 'step_four_static_lta',
  StepFiveMarkupLta = 'step_five_markup_lta',
  StepFiveStaticLta = 'step_five_static_lta',
  StepSixMarkupLta = 'step_six_markup_lta',
  TimeStepOneGig = 'time_step_one_gig',
  TimeStepTwoGig = 'time_step_two_gig',
  TimeStepThreeGig = 'time_step_three_gig',
  TimeStepFourGig = 'time_step_four_gig',
  TimeStepFiveGig = 'time_step_five_gig',
  TimeStepOneLta = 'time_step_one_lta',
  TimeStepTwoLta = 'time_step_two_lta',
  TimeStepThreeLta = 'time_step_three_lta',
  TimeStepFourLta = 'time_step_four_lta',
  TimeStepFiveLta = 'time_step_five_lta',
  WorkerListSearch = 'worker_list_search',
  OrderCreateHireWorker = 'order_create_hire_worker',
  OrderCreateInviteWorker = 'order_create_invite_worker',
  EditJobRates = 'edit_job_rates',
  JobHireWorker = 'job_hire_worker',
  JobInviteWorker = 'job_invite_worker',
  DisableBillingAccount = 'disable_billing_account',
  ReEnableBillingAccount = 're_enable_billing_account',
  ViewJobDetails = 'view_job_details',
  TimesheetComment = 'timesheet_comment',
  TimesheetRating = 'timesheet_rating',
  TimesheetViewMap = 'timesheet_view_map',
  ClientAppUpdate = 'client_app_update',
  ClientAppForceUpdated = 'client_app_force_updated',
  Worker_Favorited_From_Modal = 'worker_favorited_from_modal',
  Worker_Unfavorite_From_Modal = 'worker_unfavorite_from_modal',
  Worker_Blocked_From_Modal = 'worker_blocked_from_modal',
  Worker_Unblocked_From_Modal = 'worker_unblocked_from_modal',
  Worker_Favorited_From_Job_Detail_Page = 'worker_favorited_from_job_detail_page',
  Worker_Unfavorite_From_Job_Detail_Page = 'worker_unfavorite_from_job_detail_page',
  Worker_Blocked_From_Job_Detail_Page = 'worker_blocked_from_job_detail_page',
  Worker_Unblocked_From_Job_Detail_Page = 'worker_unblocked_from_job_detail_page',
  Worker_Favorited_From_Customer_Detail_Page = 'worker_favorited_from_customer_detail_page',
  Worker_Unfavorite_From_Customer_Detail_Page = 'worker_unfavorite_from_customer_detail_page',
  Worker_Blocked_From_Customer_Detail_Page = 'worker_blocked_from_customer_detail_page',
  Worker_Unblocked_From_Customer_Detail_Page = 'worker_unblocked_from_customer_detail_page',
  TenantLogin = 'tenant_login',
  CreateTenantAdmin = 'create_tenant_admin',
  CreateHelpdeskAdmin = 'create_helpdesk_admin',
  CreateClientAdmin = 'create_client_admin',
  BookMarkDashboardFilters = 'bookmark_dashboard_filters',
  DashbaordClientFilter = 'dashboard_client_filter',
  DashboardBillingAccountFilter = 'dashboard_billing_account_filter',
  DashboardSkillsFilter = 'dashboard_skills_filter',
  DashboardJobStatusFilter = 'dashboard_job_status_filter',
  DashboardFillStatusFilter = 'dashboard_fill_status_filter',
  DashboardMoreOptionsFilter = 'dashboard_more_options_filter',
  DashboardNoShowFilter = 'dashboard_no_show_filter',
  DashboardRegionFilter = 'dashboard_region_filter',
  ViewLastWeekPressed = 'view_last_week_pressed',
  DismissNeedsApproval = 'dismiss_needs_approval',
  OrderFilterApprovalStatus = 'order_filter_approval_status',
  DashboardMobileFriendlyNavigation = 'dashboard_mobile_friendly_navigation',
  DashboardStartDateFromUrl = 'dashboard_start_date_from_url',
  DashboardNonCurrentWeekNavigation = 'dashboard_non_current_week_navigation',
  BadgeAddedOnJobCreation = 'badge_added_on_job_creation',
  BadgeAddedAfterJobCreation = 'badge_added_after_job_creation',
  BadgeRemovedAfterJobCreation = 'badge_removed_after_job_creation',
  BadgeRemovedOnClient = 'badge_removed_on_client',
  BadgeAddedOnClient = 'badge_added_on_client',
  OpenInAppClickedFromPhone = 'open_in_app_clicked_from_phone',
  OpenInAppClickedFromWeb = 'open_in_app_clicked_from_web',
  ReleaseNoteAdded = 'release_note_added',
  ReleaseNoteEdited = 'release_note_edited',
  ReleaseNoteDeleted = 'release_note_deleted',
}

export const eventMap = {
  [GAEvent.Signup]: {
    category: 'user',
    action: 'customer_signup',
    label: 'customer_signup',
  },
  [GAEvent.FirstLogin]: {
    category: 'user',
    action: 'first_login',
    label: 'first_login',
  },
  [GAEvent.BillingSetup]: {
    category: 'user',
    action: 'setup_billing',
    label: 'setup_billing',
  },
  [GAEvent.SetPaymentMethod]: {
    category: 'user',
    action: 'setup_payment_method',
    label: 'setup_payment_method',
  },
  [GAEvent.Login]: {
    category: 'user',
    action: 'customer_login',
    label: 'customer_login',
  },
  [GAEvent.TenantLogin]: {
    category: 'user',
    action: 'tenant_login',
    label: 'tenant_login',
  },
  [GAEvent.TimesheetApprove]: {
    category: 'timesheet',
    action: 'approve_timesheet',
    label: 'approve_timesheet',
  },
  [GAEvent.TimesheetSave]: {
    category: 'timesheet',
    action: 'save_timesheet',
    label: 'save_timesheet',
  },
  [GAEvent.TimesheetReject]: {
    category: 'timesheet',
    action: 'reject_timesheet',
    label: 'reject_timesheet',
  },
  [GAEvent.TimesheetUnapprove]: {
    category: 'timesheet',
    action: 'unapprove_timesheet',
    label: 'unapprove_timesheet',
  },
  [GAEvent.TimesheetUnReject]: {
    category: 'timesheet',
    action: 'unreject_timesheet',
    label: 'unreject_timesheet',
  },
  [GAEvent.StepOneGig]: {
    category: 'order_create',
    action: 'step_1_gig',
    label: 'skill_selection',
  },
  [GAEvent.StepTwoGig]: {
    category: 'order_create',
    action: 'step_2_gig',
    label: 'schedule',
  },
  [GAEvent.StepThreeGig]: {
    category: 'order_create',
    action: 'step_3_gig',
    label: 'staffing',
  },
  [GAEvent.StepFourMarkupGig]: {
    category: 'order_create',
    action: 'step_4_gig_markup',
    label: 'instructions',
  },
  [GAEvent.StepFourStaticGig]: {
    category: 'order_create',
    action: 'step_4_gig_static',
    label: 'instructions',
  },
  [GAEvent.StepFiveMarkupGig]: {
    category: 'order_create',
    action: 'step_5_gig_markup',
    label: 'pay_rate',
  },
  [GAEvent.StepFiveStaticGig]: {
    category: 'order_create',
    action: 'step_5_gig_static',
    label: 'submission',
  },
  [GAEvent.StepSixMarkupGig]: {
    category: 'order_create',
    action: 'step_6_gig_markup',
    label: 'submission',
  },
  [GAEvent.StepOneLta]: {
    category: 'order_create',
    action: 'step_1_lta',
    label: 'skill_selection',
  },
  [GAEvent.StepTwoLta]: {
    category: 'order_create',
    action: 'step_2_lta',
    label: 'schedule',
  },
  [GAEvent.StepThreeLta]: {
    category: 'order_create',
    action: 'step_3_lta',
    label: 'staffing',
  },
  [GAEvent.StepFourStaticLta]: {
    category: 'order_create',
    action: 'step_4_lta_static',
    label: 'instruction',
  },
  [GAEvent.StepFourMarkupLta]: {
    category: 'order_create',
    action: 'step_4_lta_markup',
    label: 'instruction',
  },
  [GAEvent.StepFiveMarkupLta]: {
    category: 'order_create',
    action: 'step_5_lta_markup',
    label: 'pay_rate',
  },
  [GAEvent.StepFiveStaticLta]: {
    category: 'order_create',
    action: 'step_5_lta_static',
    label: 'submission',
  },
  [GAEvent.StepSixMarkupLta]: {
    category: 'order_create',
    action: 'step_6_lta_markup',
    label: 'submission',
  },
  [WorkerTabs.All]: {
    category: 'worker_tabs',
    action: 'all_tab',
    label: 'all_tab',
  },
  [WorkerTabs.Favorites]: {
    category: 'worker_tabs',
    action: 'favorites_tab',
    label: 'favorites_tab',
  },
  [WorkerTabs.GravyTrained]: {
    category: 'worker_tabs',
    action: 'gravy_trained_tab',
    label: 'gravy_trained_tab',
  },
  [WorkerTabs.HighlyRated]: {
    category: 'worker_tabs',
    action: 'highly_rated_tab',
    label: 'highly_rated_tab',
  },
  [WorkerTabs.PastWorkers]: {
    category: 'worker_tabs',
    action: 'past_worker_tab',
    label: 'past_worker_tab',
  },
  [WorkerTabs.Others]: {
    category: 'others',
    action: 'others_tab',
    label: 'others_tab',
  },
  [GAEvent.WorkerListSearch]: {
    category: 'worker_list',
    action: 'worker_list_search',
    label: 'worker_list_search',
  },
  [GAEvent.OrderCreateHireWorker]: {
    category: 'order_create',
    action: 'order_create_hire_worker',
    label: 'order_create_hire_worker',
  },
  [GAEvent.OrderCreateInviteWorker]: {
    category: 'order_create',
    action: 'order_create_invite_worker',
    label: 'order_create_invite_worker',
  },
  [GAEvent.EditJobRates]: {
    category: 'edit_job',
    action: 'edit_job_rates',
    label: 'edit_job_rates',
  },
  [GAEvent.JobHireWorker]: {
    category: 'job_update',
    action: 'hire_worker',
    label: 'job_hire_worker',
  },
  [GAEvent.JobInviteWorker]: {
    category: 'job_update',
    action: 'invite_worker',
    label: 'invite_worker',
  },
  [GAEvent.DisableBillingAccount]: {
    category: 'billing_account',
    action: 'disable_billing_account',
    label: 'disable_billing_account',
  },
  [GAEvent.ReEnableBillingAccount]: {
    category: 'billing_account',
    action: 're_enable_billing_account',
    label: 're_enable_billing_account',
  },
  [GAEvent.ViewJobDetails]: {
    category: 'job_details',
    action: 'view_job_details',
    label: 'view_job_details',
  },
  [GAEvent.TimesheetComment]: {
    category: 'timesheet',
    action: 'timesheet_comment',
    label: 'timesheet_comment',
  },
  [GAEvent.TimesheetRating]: {
    category: 'timesheet',
    action: 'timesheet_rating',
    label: 'timesheet_rating',
  },
  [GAEvent.TimesheetViewMap]: {
    category: 'timesheet',
    action: 'timesheet_view_map',
    label: 'timesheet_view_map',
  },
  [GAEvent.Worker_Favorited_From_Modal]: {
    category: 'worker_list',
    action: 'worker_marked_as_favorite_from_modal',
    label: 'worker_marked_as_favorite_from_modal',
  },
  [GAEvent.Worker_Unfavorite_From_Modal]: {
    category: 'worker_list',
    action: 'worker_unmarked_as_favorite_from_modal',
    label: 'worker_unmarked_as_favorite_from_modal',
  },
  [GAEvent.Worker_Blocked_From_Modal]: {
    category: 'worker_list',
    action: 'worker_marked_as_blocked_from_modal',
    label: 'worker_marked_as_blocked_from_modal',
  },
  [GAEvent.Worker_Unblocked_From_Modal]: {
    category: 'worker_list',
    action: 'worker_unmarked_as_block_from_modal',
    label: 'worker_unmarked_as_block_from_modal',
  },
  [GAEvent.Worker_Favorited_From_Job_Detail_Page]: {
    category: 'worker_list',
    action: 'worker_marked_as_favorite_from_job_detail_page',
    label: 'worker_marked_as_favorite_from_job_detail_page',
  },
  [GAEvent.Worker_Unfavorite_From_Job_Detail_Page]: {
    category: 'worker_list',
    action: 'worker_unmarked_as_favorite_from_job_detail_page',
    label: 'worker_unmarked_as_favorite_from_job_detail_page',
  },
  [GAEvent.Worker_Blocked_From_Job_Detail_Page]: {
    category: 'worker_list',
    action: 'worker_marked_as_blocked_from_job_detail_page',
    label: 'worker_marked_as_blocked_from_job_detail_page',
  },
  [GAEvent.Worker_Unblocked_From_Job_Detail_Page]: {
    category: 'worker_list',
    action: 'worker_unmarked_as_block_from_job_detail_page',
    label: 'worker_unmarked_as_block_from_job_detail_page',
  },
  [GAEvent.Worker_Favorited_From_Customer_Detail_Page]: {
    category: 'worker_list',
    action: 'worker_marked_as_favorite_from_customer_detail_page',
    label: 'worker_marked_as_favorite_from_customer_detail_page',
  },
  [GAEvent.Worker_Unfavorite_From_Customer_Detail_Page]: {
    category: 'worker_list',
    action: 'worker_unmarked_as_favorite_from_customer_detail_page',
    label: 'worker_unmarked_as_favorite_from_customer_detail_page',
  },
  [GAEvent.Worker_Blocked_From_Customer_Detail_Page]: {
    category: 'worker_list',
    action: 'worker_marked_as_blocked_from_customer_detail_page',
    label: 'worker_marked_as_blocked_from_customer_detail_page',
  },
  [GAEvent.Worker_Unblocked_From_Customer_Detail_Page]: {
    category: 'worker_list',
    action: 'worker_unmarked_as_block_from_customer_detail_page',
    label: 'worker_unmarked_as_block_from_customer_detail_page',
  },
  [GAEvent.CreateTenantAdmin]: {
    category: 'tenant_admin_created',
    action: 'tenant_admin_has_been_created',
    label: 'tenant_admin_has_been_created',
  },
  [GAEvent.CreateHelpdeskAdmin]: {
    category: 'helpdesk_admin_created',
    action: 'helpdesk_admin_has_been_created',
    label: 'helpdesk_admin_has_been_created',
  },
  [GAEvent.CreateClientAdmin]: {
    category: 'client_admin_created',
    action: 'client_admin_has_been_created',
    label: 'client_admin_has_been_created',
  },
  [GAEvent.BookMarkDashboardFilters]: {
    category: 'dashboard_filters',
    action: 'bookmark_dashboard_filters',
    label: 'bookmark_dashboard_filters',
  },
  [GAEvent.DashbaordClientFilter]: {
    category: 'dashboard_filters',
    action: 'client_filter_applied',
    label: 'client_filter_applied',
  },
  [GAEvent.DashboardBillingAccountFilter]: {
    category: 'dashboard_filters',
    action: 'billing_account_filter_applied',
    label: 'billing_account_filter_applied',
  },
  [GAEvent.DashboardSkillsFilter]: {
    category: 'dashboard_filters',
    action: 'skills_filter_applied',
    label: 'skills_filter_applied',
  },
  [GAEvent.DashboardJobStatusFilter]: {
    category: 'dashboard_filters',
    action: 'job_status_filter_applied',
    label: 'job_status_filter_applied',
  },
  [GAEvent.DashboardFillStatusFilter]: {
    category: 'dashboard_filters',
    action: 'fill_status_filter_applied',
    label: 'fill_status_filter_applied',
  },
  [GAEvent.DashboardMoreOptionsFilter]: {
    category: 'dashboard_filters',
    action: 'more_options_filter_applied',
    label: 'more_options_filter_applied',
  },
  [GAEvent.DashboardNoShowFilter]: {
    category: 'dashboard_filters',
    action: 'no_show_filter_applied',
    label: 'no_show_filter_applied',
  },
  [GAEvent.DashboardRegionFilter]: {
    category: 'dashboard_filters',
    action: 'region_filter_applied',
    label: 'region_filter_applied',
  },
  [GAEvent.ViewLastWeekPressed]: {
    category: 'view_last_week',
    action: 'view_last_week_pressed',
    label: 'view_last_week_pressed',
  },
  [GAEvent.DismissNeedsApproval]: {
    category: 'dismiss_needs_approval',
    action: 'dismiss_needs_approval',
    label: 'dismiss_needs_approval',
  },
  [GAEvent.DashboardMobileFriendlyNavigation]: {
    category: 'dashboard_mobile_friendly_navigation',
    action: 'dashboard_accessed_through_mobile_friendly_navigation',
    label: 'dashboard_accessed_through_mobile_friendly_navigation',
  },
  [GAEvent.DashboardStartDateFromUrl]: {
    category: 'dashboard_start_date_from_url',
    action: 'dashboard_start_date_from_url',
    label: 'dashboard_start_date_from_url',
  },
  [GAEvent.DashboardNonCurrentWeekNavigation]: {
    category: 'dashboard_non_current_week_navigation',
    action: 'dashboard_non_current_week_navigation',
    label: 'dashboard_non_current_week_navigation',
  },
  [GAEvent.BadgeAddedOnJobCreation]: {
    category: 'badge_added_on_job_creation',
    action: 'badge_added_on_job_creation',
    label: 'badge_added_on_job_creation',
  },
  [GAEvent.BadgeAddedAfterJobCreation]: {
    category: 'badge_added_after_job_creation',
    action: 'badge_added_after_job_creation',
    label: 'badge_added_after_job_creation',
  },
  [GAEvent.BadgeRemovedAfterJobCreation]: {
    category: 'badge_removed_after_job_creation',
    action: 'badge_removed_after_job_creation',
    label: 'badge_removed_after_job_creation',
  },
  [GAEvent.BadgeRemovedOnClient]: {
    category: 'badge_removed_on_client',
    action: 'badge_removed_on_client',
    label: 'badge_removed_on_client',
  },
  [GAEvent.BadgeAddedOnClient]: {
    category: 'badge_added_on_client',
    action: 'badge_added_on_client',
    label: 'badge_added_on_client',
  },
  [GAEvent.OpenInAppClickedFromPhone]: {
    category: 'open_in_app_clicked',
    action: 'open_in_app_clicked',
    label: 'open_in_app_clicked_from_phone',
  },
  [GAEvent.OpenInAppClickedFromWeb]: {
    category: 'open_in_app_clicked',
    action: 'open_in_app_clicked',
    label: 'open_in_app_clicked_from_web',
  },
  [GAEvent.ReleaseNoteAdded]: {
    category: 'release_note',
    action: 'release_note_added',
    label: 'release_note_added',
  },
  [GAEvent.ReleaseNoteEdited]: {
    category: 'release_note',
    action: 'release_note_edited',
    label: 'release_note_edited',
  },
  [GAEvent.ReleaseNoteDeleted]: {
    category: 'release_note',
    action: 'release_note_deleted',
    label: 'release_note_deleted',
  },
};
