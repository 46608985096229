import { useNotificationStatsResetMutation } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import { NotificationStatsTypeEnum } from '@/types/graphql';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useEffect, useState } from 'react';
import LoadingState from '../LoadingState';
import Modal from '../Modal';
import NotificationListItem from './NotificationListItem';
import {
  CenterContainer,
  Container,
  ContainerBottomSheet,
  Divider,
  Header,
  HeaderTitle,
  Notch,
  Subtitle,
} from './styles';

interface NotificationComponentProps {
  open: boolean;
  data: any;
  hideRedDot: () => void;
  loadMoreNotifications: () => void;
  loading: boolean;
  enableReadAll: boolean;
  readNotification: (id: string) => void;
}

const NotificationComponent = ({
  open,
  data,
  hideRedDot,
  loadMoreNotifications,
  enableReadAll,
  readNotification,
  loading,
}: NotificationComponentProps) => {
  const [isOpen, setIsOpen] = useState(open);
  const [resetRead] = useNotificationStatsResetMutation({
    onCompleted() {
      hideRedDot();
    },
  });

  const handleClickOutside = (event: MouseEvent) => {
    if (
      event.target instanceof Node &&
      !document.getElementById('notification-popup')?.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const renderNotificationBottomSheet = () => {
    return (
      <Modal open={isOpen} onClose={() => setIsOpen(false)} noHeader={true}>
        <ContainerBottomSheet
          id="notification-popup"
          onScroll={(e) => {
            const bottom =
              e.currentTarget.scrollHeight - e.currentTarget.scrollTop <=
                e.currentTarget.clientHeight + 5 &&
              e.currentTarget.scrollHeight - e.currentTarget.scrollTop >=
                e.currentTarget.clientHeight - 5;
            if (bottom) {
              loadMoreNotifications();
            }
          }}
        >
          <Notch />
          <Header>
            <HeaderTitle>Notifications</HeaderTitle>
            <div
              onClick={() => {
                if (enableReadAll) resetUnreadCount();
              }}
            >
              <DoneAllIcon htmlColor="#64748B" />
            </div>
          </Header>
          {Array.isArray(data) && data.length > 0 ? (
            data.map((item, index) => {
              return (
                <NotificationListItem
                  hideRedDot={!enableReadAll}
                  item={item}
                  key={index}
                  updateNotification={readNotification}
                />
              );
            })
          ) : (
            <CenterContainer>
              <HeaderTitle>{'No notifications available'}</HeaderTitle>
            </CenterContainer>
          )}
          {loading && <LoadingState />}
        </ContainerBottomSheet>
      </Modal>
    );
  };

  const resetUnreadCount = async () => {
    await resetRead({
      variables: {
        notificationStatsType: NotificationStatsTypeEnum.RELEASE_NOTES,
      },
    });
  };

  return (
    isOpen &&
    (phoneOnly ? (
      renderNotificationBottomSheet()
    ) : (
      <Container
        id="notification-popup"
        onScroll={(e) => {
          const bottom =
            e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
            e.currentTarget.clientHeight;

          if (bottom) {
            loadMoreNotifications();
          }
        }}
      >
        <Header>
          <HeaderTitle>Notifications</HeaderTitle>
          <Subtitle
            onClick={() => {
              if (enableReadAll) resetUnreadCount();
            }}
          >
            {'Mark all as read'}
          </Subtitle>
        </Header>
        <Divider />
        {Array.isArray(data) && data.length > 0 ? (
          data.map((item, index) => {
            return (
              <NotificationListItem
                hideRedDot={!enableReadAll}
                item={item}
                key={index}
                updateNotification={readNotification}
              />
            );
          })
        ) : (
          <CenterContainer>
            <Subtitle>{'No notifications available'}</Subtitle>
          </CenterContainer>
        )}
        {loading && <LoadingState />}
      </Container>
    ))
  );
};

export default NotificationComponent;
